import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Fade,
  Modal,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { REST, theme } from "Utils";

const Payments = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    amount: "",
    ibanReceiver: "",
    ibanSender: "",
    idReceiver: "",
    idSender: "",
    paymentDetails: "",
  });

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const checkFormValidity = () => {};

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await REST.post(`api/v1/core/payment/normal`, formData);
      setOpen(false);
    } catch (error) {
      console.error("Error while submitting form: ", error);
    }
    setOpen(false);
  };

  useEffect(() => {
    checkFormValidity();
  }, [formData]);
  return (
    <ThemeProvider theme={theme}>
      <div style={{ maxWidth: "500px" }}>
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onChange={handleInputChange}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <TextField
              id='idSender'
              label='Sender ID'
              variant='outlined'
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id='ibanSender'
              label='Sender IBAN'
              variant='outlined'
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <TextField
              id='idReceiver'
              label='Receiver ID'
              variant='outlined'
              style={{ marginBottom: "10px" }}
            />
            <TextField
              id='ibanReceiver'
              label='Receiver IBAN'
              variant='outlined'
              style={{ marginBottom: "10px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <TextField
              id='amount'
              label='Amount'
              variant='outlined'
              style={{ marginBottom: "10px" }}
            />
            {/* <TextField
              id='outlined-basic'
              label='Moneda'
              variant='outlined'
              style={{ marginBottom: "10px" }}
            /> */}
          </div>
          <TextField
            id='paymentDetails'
            label='Payment Details'
            variant='outlined'
            style={{ marginBottom: "10px" }}
          />
          <Button variant='contained' color='primary' onClick={handleOpen}>
            Pay
          </Button>

          {/* Confirmation Modal */}
          <Modal
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div
                style={{
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "4px",
                }}
              >
                <h2>Confirmation</h2>
                <p>Are you sure you want to send the payment?</p>
                <Button
                  style={{ marginRight: "10px" }}
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}
                >
                  Yes, send payment
                </Button>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
            </Fade>
          </Modal>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default Payments;
