import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import lscache from "lscache";
import Autocomplete from "@material-ui/lab/Autocomplete";

const MultipleDropDownInput = (props) => {
  const {
    item,
    formState,
    handleChange,
    hasError,
    setValueSecondDD,
    setValueThirdDD,
    valueSecondDD,
    valueThirdDD,
  } = props;

  const [error, setError] = useState({ message: null });
  const [showSecondDD, setShowSecondDD] = React.useState(
    lscache.get("operatorType") == "Operator A" ? false : true
  );
  const [showThirdDD, setShowThirdDD] = React.useState(
    lscache.get("operatorType") == "Operator A" ? false : true
  );
  console.log("show thrid", valueThirdDD);
  var itemName = item.key;
  return (
    <div className="questionGrid">
      <div className="answerBox">
        <div className="dropDownContainer">
          <Autocomplete
            options={item.answer.selectList}
            getOptionLabel={(option) => (option.key ? option.key : option)}
            defaultValue={
              formState.values[itemName]
                ? formState.values[itemName]["spec"]
                : "Alege"
            }
            onChange={(event, value) => {
              event.persist();
              handleChange(
                {
                  target: {
                    type: "SelectTree",
                    name: itemName,
                    value: event.target.textContent,
                  },
                  persist: () => true,
                },
                setValueSecondDD,
                value
              );
              setShowSecondDD(true);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={`${itemName}`}
                name={`${itemName}`}
                style={{ width: 300 }}
                dataType="selectMany"
                value={
                  formState.values[itemName]
                    ? formState.values[itemName]["spec"]
                    : "Alege"
                }
                label="Specializare"
                margin="normal"
              />
            )}
          />

          {showSecondDD ? (
            <Autocomplete
              options={valueSecondDD}
              getOptionLabel={(option) => (option.key ? option.key : option)}
              defaultValue={
                formState.values[itemName]
                  ? formState.values[itemName]["spital"]
                  : "Alege"
              }
              style={{ width: 300 }}
              onChange={(event, value) => {
                event.persist();
                handleChange(
                  {
                    target: {
                      type: "SelectTree",
                      name: itemName,
                      value: event.target.textContent,
                    },
                    persist: () => true,
                  },
                  setValueThirdDD,
                  value
                );
                setShowThirdDD(true);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${itemName}`}
                  name={`${itemName}`}
                  value={
                    formState.values[itemName]
                      ? formState.values[itemName]["spital"]
                      : "Alege"
                  }
                  label="Spital"
                  margin="normal"
                />
              )}
            />
          ) : (
            <div></div>
          )}
          {showThirdDD  ? (
            <Autocomplete
              options={valueThirdDD ? valueThirdDD : []}
              getOptionLabel={(option) => (option.key ? option.key : option)}
              defaultValue={
                formState.values[itemName]
                  ? formState.values[itemName]["medic"]
                  : "Alege"
              }
              style={{ width: 300 }}
              onChange={(event, value) => {
                event.persist();
                handleChange(
                  {
                    target: {
                      type: "SelectTree",
                      name: itemName,
                      value: event.target.textContent,
                    },
                    persist: () => true,
                  },
                  () => true,
                  value
                );
              }}
              error={hasError(itemName)}
              helperText={
                hasError(itemName) ? formState.errors[itemName][0] : null
              }
              value={
                formState.values[itemName]
                  ? formState.values[itemName]["medic"]
                  : ""
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${itemName}`}
                  name={`${itemName}`}
                  value={
                    formState.values[itemName]
                      ? formState.values[itemName]["medic"]
                      : "Alege"
                  }
                  label="Medic"
                  margin="normal"
                />
              )}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {lscache.get("operatorType") == "Operator A" ? (
        <div className="answerTextContainer">
          <div className="answerDotContainer">
            <p className="answerDot">A</p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default MultipleDropDownInput;
