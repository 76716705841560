import { Table } from "@material-ui/core";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { REST, theme } from "Utils";
import lscache from "lscache";
import moment from "moment";
import React, { useEffect, useState } from "react";

const PersonalTransactions = () => {
  const formatterEUR = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "EUR",
  });

  const formatterUSD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatterGBP = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  });

  const formatterRON = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "RON",
  });
  const [transactions, setTransactions] = useState([]);
  const userID = lscache.get("userID");
  useEffect(() => {
    REST.get(`api/v1/core/transactions?userID=${userID}&size=10`)
      .then((answer) => {
        console.log(answer.data);
        setTransactions(answer.data);
      })
      .catch((err) => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("GET TRANSACTIONS ERROR: ", message);
        }
      });
  }, []);
  return (
    <>
      <Table className='table table-hover text-nowrap mb-0'>
        <thead>
          <tr>
            <th className='bg-white text-left'>ID</th>
            <th className='bg-white'>SENDER NAME</th>
            <th className='bg-white'>SENDER IBAN</th>
            <th className='bg-white'>RECEIVER NAME</th>
            <th className='bg-white'>RECEIVER IBAN</th>
            <th className='bg-white'>AMOUNT</th>
            {/* <th className='bg-white'>PAYMENT DETAILS</th> */}
            <th className='bg-white'>TRANSACTION DATE</th>
          </tr>
        </thead>
        <tbody className='bg-white'>
          {transactions.map((item, index) => (
            <tr key={`entry--${index}`}>
              <td className='font-weight-bold'>#{item.id}</td>
              <td>
                <div className='d-flex align-items-center'>
                  <div>{item.senderName}</div>
                </div>
              </td>
              <td>{item.senderIban}</td>
              <td>{item.receiverName}</td>
              <td>
                <div className='d-flex align-items-center'>
                  <div>{item.receiverIban}</div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  {item.currency == "EUR" ? (
                    <div>{formatterEUR.format(item.amount)}</div>
                  ) : null}
                  {item.currency == "USD" ? (
                    <div>{formatterUSD.format(item.amount)}</div>
                  ) : null}
                  {item.currency == "GBP" ? (
                    <div>{formatterGBP.format(item.amount)}</div>
                  ) : null}
                  {item.currency == "RON" ? (
                    <div>{formatterRON.format(item.amount)}</div>
                  ) : null}
                </div>
              </td>
              {/* <td>{item.paymentDetails}</td> */}
              <td>
                {" "}
                {moment(item.creationDate).format("DD-MM-YYYY HH:mm:SS")}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PersonalTransactions;
