import React, { useState, useEffect } from "react";
import lscache from "lscache";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DatePickerInput = (props) => {
  const { item, formState, handleChange, hasError, handleBlur } = props;
  const [error, setError] = useState({ message: null });

  var itemName = item.key;

  return (
      <div className="answerBox">
        <div className="datePickerContainer">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id={`${itemName}`}
              name={`${itemName}`}
              placeholder={"DD/MM/YYYY"}
              format="dd/MM/yyyy"
              className="textField"
              onChange={(event) => {
                handleChange({
                  target: {
                    type: "Date",
                    name: itemName,
                    value: event,
                  },
                  persist: () => true,
                });
              }}
              value={
                formState.values[itemName] !== undefined
                  ? formState.values[itemName]
                  : "00/00/0000"
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
  
      {lscache.get("operatorType") == "Operator A" ? (
        <div className="answerTextContainer">
          <div className="answerDotContainer">
            <p className="answerDot">A</p>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default DatePickerInput;
