import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import { LeftSidebar } from "../../layout-blueprints";
import { AnimatePresence, motion } from "framer-motion";

const useStyles = makeStyles(() => ({
  root: {
    // paddingTop: 64,
    // height: "100%"
  },
  content: {
    height: "100%"
  }
}));

// import { useLocation } from "react-router-dom";

const IsLogged = props => {
  const { location, children } = props;

  // const lllLocation = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.3
  };

  const pageName = location.pathname.split("/")[1] || "homepage";

  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <div className={`pageWrapper ${pageName}`}>
        <main className={classes.content}>
          <LeftSidebar>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}
            >
              {children}
            </motion.div>
          </LeftSidebar>
        </main>
      </div>
    </div>
  );
};

IsLogged.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default IsLogged;
