import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    // paddingTop: 64,
    // height: "100%"
  },
  content: {
    height: "100%"
  }
}));

const Website = props => {
  const { location, children } = props;

  const pageName = location.pathname.split("/")[1] || "homepage";

  const classes = useStyles();
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true
  });

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >
      <div className={`pageWrapper ${pageName}`}>
        <main className={classes.content}>{children}</main>
        {/*<_footer />*/}
      </div>
    </div>
  );
};

Website.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Website;
