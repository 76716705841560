import lscache from "lscache";

const AuthHeader = () => {
  const accessToken = lscache.get("accessToken");

  console.log("HHH >>>", accessToken);

  if (accessToken) {
    return { Authorization: `${accessToken}` };
  } else {
    return {};
  }
};

export default AuthHeader;
