import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Table,
  InputAdornment,
  Card,
  Button,
  TextField
} from "@material-ui/core";
import * as moment from "moment";
import lscache from "lscache";

import Pagination from "@material-ui/lab/Pagination";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";

import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

const Tables = props => {
  const {
    data,
    maxPage,
    handleChangePage,
    page,
    handleEdit,
    handleCheck,
    handleRejectEntry
  } = props;

  const [entries, setEntries] = useState("1");

  const handleChange = event => {
    setEntries(event.target.value);
  };

  const handleSelectEntry = () => {
    alert("CHESTIONAR SELECTAT");
  };

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        {/* <div className="card-header py-3">
          <div className="card-header--title font-size-lg">
            Lista tichete
          </div>
        </div> */}
        <div className="d-flex justify-content-between px-4 py-3">
          {/* <div className="search-wrapper">
            <TextField
              variant="outlined"
              size="small"
              id="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div> */}
        </div>
        <div className="table-responsive-md">
          <Table className="table table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="bg-white text-left">ID</th>
                <th className="bg-white">FIRST NAME</th>
                <th className="bg-white ">LAST NAME</th>
                <th className="bg-white">EMAIL</th>
                <th className="bg-white ">CITIZENSHIP</th>
                <th className="bg-white">DATE OF BIRTH</th>
                <th className="bg-white">PHONE NUMBER</th>
                <th className="bg-white">VALID</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr>
                  <td className="font-weight-bold">#{item.id}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>{item.firstName}</div>
                    </div>
                  </td>
                  <td>{item.lastName}</td>
                  <td>
                    <div
                      className="avatar-icon-wrapper avatar-icon-sm"
                      title="Lili Pemberton"
                    >
                      {item.email}
                    </div>
                  </td>
                  <td >
                    <div className="d-flex align-items-center">
                      <div>{item.citizenship}</div>
                    </div>
                  </td>
                  <td >
                    {" "}
                    {moment(item.creationDate).format("DD-MM-YYYY")}
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>{item.phoneNumber}</div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {item.valid == "1" ? <div>true</div> : <div>false</div>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={maxPage}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </>
  );
};

export default Tables;
