import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import validate from "validate.js";
import loginFormSchema from "../../../Schemas/loginFormSchema";
import { REST, beURL } from "../../../Utils/index";
import lscache from "lscache";
import logoImage from "../../../assets/images/logoFull.png";
import { Grid, Container, Card, Button, TextField } from "@material-ui/core";
import AuthHeader from "../../../Services/AuthHeader";
import axios from "axios";

import hero6 from "../../../assets/images/hero-bg/hero-1.jpg";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = (props) => {
  const classes = useStyles();

  const { history, location } = props;

  // const GlobalStyle = GlobalStyle({ color: 'cadetblue' });

  const [error, setError] = useState({ message: null });
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    const errors = validate(formState.values, loginFormSchema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const handleSignIn = async (event) => {
    setWrongCredentials(false);

    event.preventDefault();
    if (formState.isValid) {
      await REST.post(`auth/LYNED_CLIENT/generateToken`, formState.values)
        .then((answer) => {
          setError({ message: null });

          if (answer.data.token) {
            if (formState.values.username === "bank1@email.com") {
              lscache.set("userID", 14);
            } else if (formState.values.username === "bank3@email.com") {
              lscache.set("userID", 17);
            }
            const accessToken = answer.data.token;
            lscache.set("accessToken", accessToken);
            window.location = "/usersList";
          }
        })
        .catch((err) => {
          console.log("OPERATOR TYEP >>>>", err);

          if (err && err.response && err.response.data) {
            const { message } = err.response;
            setError({
              message,
            });
          }
          if (err == "Error: Request failed with status code 401") {
            setWrongCredentials(true);
          }
        });
      // window.location = "/usersList";
    } else {
      setError({ message: null });
    }
  };

  return (
    <>
      <div className='app-wrapper min-vh-100 bg-white'>
        <div className='hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100'>
          <div
            className='flex-grow-1 w-100 d-flex align-items-center'
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className='bg-composed-wrapper--image opacity-6'
              style={{ backgroundImage: "url(" + hero6 + ")" }}
            />
            <div className='bg-composed-wrapper--bg bg-second opacity-7' />
            <div
              className='bg-composed-wrapper--content p-3 p-md-5'
              style={{ width: "40%" }}
            >
              <Container>
                <Card className='rounded-sm modal-content p-3 bg-white-10'>
                  <Card className='rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0'>
                    <Grid
                      container
                      spacing={0}
                      style={{ padding: "40px", backgroundColor: "#394455" }}
                    >
                      <Grid
                        item
                        lg={12}
                        className='d-flex align-items-center justify-content-center flex-column'
                      >
                        <img
                          src={logoImage}
                          className={"logoImage"}
                          style={{
                            width: "300px",
                            height: "150px",
                            marginBottom: "40px",
                          }}
                        />
                        <form className={classes.form} noValidate>
                          <TextField
                            id='username'
                            name='username'
                            label='Username'
                            variant='outlined'
                            onChange={handleChange}
                            error={hasError("username")}
                            required
                            fullWidth
                            helperText={
                              hasError("username")
                                ? formState.errors.username[0]
                                : null
                            }
                            className={"loginFormTextInput"}
                            value={formState.values.username || ""}
                          />
                          <TextField
                            type='password'
                            required
                            fullWidth
                            id='password'
                            name='password'
                            label='Password'
                            variant='outlined'
                            onChange={handleChange}
                            error={hasError("password")}
                            helperText={
                              hasError("password")
                                ? formState.errors.password[0]
                                : null
                            }
                            className={"loginFormTextInput"}
                            value={formState.values.password || ""}
                          />
                          <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={"singInButton"}
                            onClick={handleSignIn}
                          >
                            Sign In
                          </Button>
                          {wrongCredentials ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "red",
                                fontWeight: "bold",
                                marginTop: "15px",
                              }}
                            >
                              Date de conectare incorecte!
                            </div>
                          ) : null}
                        </form>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className='hero-footer w-100 pb-4' />
        </div>
      </div>
    </>
  );
};

export default Login;
