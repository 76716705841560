import React, { useState, useEffect, useRef } from "react";
import { useInterval } from '../../../hooks';
import { REST } from "../../../Utils/index";
import Tables from "./Components/Tables";
import Wrapper from "./Components/Wrapper";
import lscache from "lscache";
import logoImage from "../../../assets/images/logo.png";
import { Button } from "@material-ui/core";
import axios from "axios";

import { Grid, Card } from '@material-ui/core';

const TransactionsList = props => {
  const { history, location } = props;

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [totalEuro, setTotalEuro] = useState(0);
  const [totalUsd, setTotalUsd] = useState(0);
  const [totalGbp, setTotalGbp] = useState(0);
  const [totalRon, setTotalRon] = useState(0);

  const getData = async (pg) => {
    console.log('INSIDE THE INTERVAL >>>', pg)

    await REST.get(`api/v1/core/transactions/getAllTransactions?page=${pg}&size=10`)
      .then(answer => {
        setData(answer.data.transactions);
        setMaxPage(answer.data.totalPages);
        // apel backend pentru returnare lista apeluri
        // setPage(page + 1);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("GET USERS ERROR: ", message);
          setMaxPage(1);
        }
      });
  };

  const getMoneyData = async () => {
    await REST.get(`api/v1/core/dashboard/getInfo`)
      .then(answer => {
        const data = answer.data;
        setTotalEuro(data.totalEuro);
        setTotalUsd(data.totalUsd);
        setTotalGbp(data.totalGbp);
        setTotalRon(data.totalRon);

        setIsPageLoading(false);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("GET USERS ERROR: ", message);
        }
      });
  }

  const handleChangePage = (event, value) => {
    console.log("SET PAGE TO: ", value - 1)
    setPage(value - 1);
  };

  const getAll = (pg) => {
    getData(pg);
    getMoneyData();
  };

  useEffect(() => {
    // getAll();
    setIsPageLoading(true);
  }, [page]);

  useInterval(() => {
    // Your custom logic here
    getAll(page);
  }, 3000);


  const formatterEUR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  });

  const formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatterGBP = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
  });

  const formatterRON = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'RON',
  });




  return (

    <div className={"listareContent"}>
      {isPageLoading && <><div className={'isPageLoading'}>Loading ...</div></>}
      <div className="mb-spacing-6">
        <Grid container spacing={6}>
          <Grid item md={6} xl={3}>
            <Card className="p-3 bg-deep-sky">
              <div className="text-white-50 pb-2">Euro EUR (€)</div>
              <div className="d-flex justify-content-between align-items-end">
                <h3 className="display-4 mb-0">
                  <span className="text-white">{formatterEUR.format(totalEuro)}</span>
                </h3>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} xl={3}>
            <Card className="p-3 bg-plum-plate">
              <div className="text-white-50 pb-2">US Dollar USD ($)</div>
              <div className="d-flex justify-content-between align-items-end">
                <h3 className="display-4 mb-0">
                  <span className="text-white">{formatterUSD.format(totalUsd)}</span>
                </h3>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} xl={3}>
            <Card className="p-3 bg-grow-early">
              <div className="text-white-50 pb-2">Pound sterling GBP (£)</div>
              <div className="d-flex justify-content-between align-items-end">
                <h3 className="display-4 mb-0">
                  <span className="text-white">{formatterGBP.format(totalGbp)}</span>
                </h3>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} xl={3}>
            <Card className="p-3 bg-nice-redora">
              <div className="text-white-50 pb-2">Romanian Leu RON</div>
              <div className="d-flex justify-content-between align-items-end">
                <h3 className="display-4 mb-0">
                  <span className="text-white">{formatterRON.format(totalRon)}</span>
                </h3>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div>

        <Wrapper>
          <Tables
            data={data}
            page={page}
            handleChangePage={handleChangePage}
            maxPage={maxPage}
            isPageLoading={isPageLoading}
          />
        </Wrapper>
      </div>
    </div>
  );

};

export default TransactionsList;
