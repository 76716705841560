import React, { useState, useEffect } from "react";
import { REST } from "../../../Utils/index";
import Tables from "./Components/Tables";
import Wrapper from "./Components/Wrapper";
import lscache from "lscache";
import logoImage from "../../../assets/images/logo.png";
import { Button } from "@material-ui/core";
import axios from "axios";


const List = props => {
  const { history, location } = props;

  const [page, setPage] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const pageToLoad = 0;



  const getData = async pageToLoad => {
    setIsPageLoading(true);
    console.log("LOADING PAGE: ", pageToLoad + 1);
    await REST.get(`api/v1/user/getAllUsers?page=${pageToLoad}&size=10`)
      .then(answer => {
        setData(answer.data.content);
        setMaxPage(answer.data.totalPages);
        setIsPageLoading(false);
        console.log(answer.data.content)
        // apel backend pentru returnare lista apeluri
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("GET USERS ERROR: ", message);
          setMaxPage(1);
        }
      });
  };

  const handleChangePage = (event, value) => {
    setPage(value - 1);
    getData(value - 1);
  };

  useEffect(() => {
    getData(0);
  }, []);

  const handleLogOut = () => {
    lscache.flush();
    window.location = "/login";
  };

  if (isPageLoading) {
    return (
      <div className={"listareContent"}>
        <div>
          <Wrapper>
            <Tables
              data={[]}
              page={page}
              handleChangePage={handleChangePage}
              maxPage={maxPage}
            />
          </Wrapper>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"listareContent"}>
        <div>
          <Wrapper>
            <Tables
              data={data}
              page={page}
              // handleEdit={handleEditEntry}
              // handleCheck={handleCheckEntry}
              // handleRejectEntry={handleRejectEntry}
              handleChangePage={handleChangePage}
              maxPage={maxPage}
            />
          </Wrapper>
        </div>
      </div>
    );
  }
};

export default List;
