import React, { useState, useEffect } from 'react';
import { REST } from "../../../Utils/index";
import { Grid, Card } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardContent } from '@material-ui/core';


const Dashboard = props => {

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [data, setData] = useState({})

  const [totalEuro, setTotalEuro] = useState(0);
  const [totalUsd, setTotalUsd] = useState(0);
  const [totalGbp, setTotalGbp] = useState(0);
  const [totalRon, setTotalRon] = useState(0);

  const [accountsEuro, setAccountsEuro] = useState(0);
  const [accountsUsd, setAccountsUsd] = useState(0);
  const [accountsGbp, setAccountsGbp] = useState(0);
  const [accountsRon, setAccountsRon] = useState(0);

  const [numberOfUsers, setNumberOfUsers] = useState(0);
  const [numberOfAccounts, setNumberOfAccounts] = useState(0);
  const [numberOfTransactions, setNumberOfTransactions] = useState(0);
  const [numberOfCards, setNumberOfCards] = useState(0);

  const getData = async () => {
    setIsPageLoading(true);

    await REST.get(`api/v1/core/dashboard/getInfo`)
      .then(answer => {
        const data = answer.data;
        setTotalEuro(data.totalEuro);
        setTotalUsd(data.totalUsd);
        setTotalGbp(data.totalGbp);
        setTotalRon(data.totalRon);

        setAccountsEuro(data.accountsEuro)
        setAccountsUsd(data.accountsUsd)
        setAccountsGbp(data.accountsGbp)
        setAccountsRon(data.accountsRon)

        setNumberOfUsers(data.numberOfUsers)
        setNumberOfAccounts(data.numberOfAccounts)
        setNumberOfTransactions(data.numberOfTransactions)
        setNumberOfCards(data.numberOfCards)

        setIsPageLoading(false);
        console.log("ANSWER: ", answer.data)
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;
          console.log("GET USERS ERROR: ", message);
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const formatterEUR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
  });

  const formatterUSD = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const formatterGBP = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'GBP',
  });

  const formatterRON = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'RON',
  });

  if (isPageLoading) {
    return (
      <div></div>
    )
  } else {
    return (
      <>
        <div>
          <div className="mb-spacing-6">
            <Grid container spacing={6}>
              <Grid item md={6} xl={3}>
                <Card className="p-3 bg-deep-sky">
                  <div className="text-white-50 pb-2">Euro EUR (€)</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0">
                      <span className="text-white">{formatterEUR.format(totalEuro)}</span>
                    </h3>
                  </div>
                </Card>
              </Grid>
              <Grid item md={6} xl={3}>
                <Card className="p-3 bg-plum-plate">
                  <div className="text-white-50 pb-2">US Dollar USD ($)</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0">
                      <span className="text-white">{formatterUSD.format(totalUsd)}</span>
                    </h3>
                  </div>
                </Card>
              </Grid>
              <Grid item md={6} xl={3}>
                <Card className="p-3 bg-grow-early">
                  <div className="text-white-50 pb-2">Pound sterling GBP (£)</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0">
                      <span className="text-white">{formatterGBP.format(totalGbp)}</span>
                    </h3>
                  </div>
                </Card>
              </Grid>
              <Grid item md={6} xl={3}>
                <Card className="p-3 bg-nice-redora">
                  <div className="text-white-50 pb-2">Romanian Leu RON</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0">
                      <span className="text-white">{formatterRON.format(totalRon)}</span>
                    </h3>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>

          {/* ACCOUNTS */}

          <div className="mb-spacing-6">
            <Grid container spacing={6}>
              <Grid item md={6} xl={3}>
                <Card className="shadow-success-sm p-3">
                  <div className="text-black-50 pb-2">Euro accounts</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0 text-success">
                      {accountsEuro}
                    </h3>
                  </div>
                </Card>
              </Grid>
              <Grid item md={6} xl={3}>
                <Card className="shadow-success-sm p-3">
                  <div className="text-black-50 pb-2">US dollars accounts</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0 text-success">
                      {accountsUsd}
                    </h3>
                  </div>
                </Card>
              </Grid>
              <Grid item md={6} xl={3}>
                <Card className="shadow-success-sm p-3">
                  <div className="text-black-50 pb-2">Pound sterlin accounts</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0 text-success">
                      {accountsGbp}
                    </h3>
                  </div>
                </Card>
              </Grid>
              <Grid item md={6} xl={3}>
                <Card className="shadow-success-sm p-3">
                  <div className="text-black-50 pb-2">Romanian leu accounts</div>
                  <div className="d-flex justify-content-between align-items-end">
                    <h3 className="display-4 mb-0 text-success">
                      {accountsRon}
                    </h3>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>

          <div className="mb-spacing-6">
            <Grid container spacing={6}>
              <Grid item xl={4} md={4} onClick={() => window.location = "/usersList"}>
                <Card className="mb-5 card-box card-box-border-bottom border-success">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={['far', 'user']}
                          className="font-size-xxl text-success"
                        />
                      </div>
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-lg pr-1"> {numberOfUsers}</b>
                        <span className="text-black-50">users</span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xl={4} md={4} onClick={() => window.location = "/transactionsList"}>
                <Card className="mb-5 card-box card-box-border-bottom border-danger">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={['far', 'building']}
                          className="font-size-xxl text-info"
                        />
                      </div>
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-lg pr-1"> {numberOfAccounts}</b>
                        <span className="text-black-50">accounts</span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xl={4} md={4} onClick={() => window.location = "/transactionsList"}>
                <Card className="mb-5 card-box card-box-border-bottom border-info">
                  <CardContent>
                    <div className="text-center">
                      <div className="mt-1">
                        <FontAwesomeIcon
                          icon={['far', 'chart-bar']}
                          className="font-size-xxl text-warning"
                        />
                      </div>
                      <div className="mt-3 line-height-sm">
                        <b className="font-size-lg pr-1"> {numberOfTransactions}</b>
                        <span className="text-black-50">transactions</span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </>
    );
  }

}


export default Dashboard;