import React from "react";

const Demo = () => {
  return (
    <p className={"demoComponent"}>
      Demo component. Remove me from page includes, pls
    </p>
  );
};

export default Demo;
