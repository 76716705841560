import React, { useState, useEffect } from "react";

import clsx from "clsx";

import { Collapse } from "@material-ui/core";

import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";
import { setSidebarToggleMobile } from "../../reducers/ThemeOptions";
import { REST } from "../../Utils/index";

import SidebarUserbox from "../SidebarUserbox";

import ChevronRightTwoToneIcon from "@material-ui/icons/ChevronRightTwoTone";
import VerifiedUserTwoToneIcon from "@material-ui/icons/VerifiedUserTwoTone";
import RoomTwoToneIcon from "@material-ui/icons/RoomTwoTone";
import CameraAltTwoToneIcon from "@material-ui/icons/CameraAltTwoTone";
import CollectionsTwoToneIcon from "@material-ui/icons/CollectionsTwoTone";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";
import BusinessCenterTwoToneIcon from "@material-ui/icons/BusinessCenterTwoTone";
import AssessmentTwoToneIcon from "@material-ui/icons/AssessmentTwoTone";
import MoveToInboxTwoToneIcon from "@material-ui/icons/MoveToInboxTwoTone";
import BallotTwoToneIcon from "@material-ui/icons/BallotTwoTone";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsTwoToneIcon from "@material-ui/icons/SettingsTwoTone";
import DepartureBoardTwoToneIcon from "@material-ui/icons/DepartureBoardTwoTone";
import LibraryBooksTwoToneIcon from "@material-ui/icons/LibraryBooksTwoTone";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import DevicesOtherTwoToneIcon from "@material-ui/icons/DevicesOtherTwoTone";
import LinkTwoToneIcon from "@material-ui/icons/LinkTwoTone";
import { AccountBalanceWallet } from "@material-ui/icons";
import { Receipt } from "@material-ui/icons";

import SecurityTwoToneIcon from "@material-ui/icons/SecurityTwoTone";
import CameraTwoToneIcon from "@material-ui/icons/CameraTwoTone";

import lscache from "lscache";

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox } = props;
  const [data, setData] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [dashboardOpen, setDashboardOpen] = useState(false);
  const toggleDashboard = (event) => {
    setDashboardOpen(!dashboardOpen);
    event.preventDefault();
  };

  const [elementsOpen, setElementsOpen] = useState(false);
  const toggleElements = (event) => {
    setElementsOpen(!elementsOpen);
    event.preventDefault();
  };

  const [pagesOpen, setPagesOpen] = useState(false);
  const togglePages = (event) => {
    setPagesOpen(!pagesOpen);
    event.preventDefault();
  };

  const [otherPagesOpen, setOtherPagesOpen] = useState(false);
  const toggleOtherPages = (event) => {
    setOtherPagesOpen(!otherPagesOpen);
    event.preventDefault();
  };

  const [applicationOpen, setApplicationOpen] = useState(false);
  const toggleApplication = (event) => {
    setApplicationOpen(!applicationOpen);
    event.preventDefault();
  };

  const [designSystemOpen, setDesignSystemOpen] = useState(false);
  const toggleDesignSystem = (event) => {
    setDesignSystemOpen(!designSystemOpen);
    event.preventDefault();
  };

  const [blocksOpen, setBlocksOpen] = useState(false);
  const toggleBlocks = (event) => {
    setBlocksOpen(!blocksOpen);
    event.preventDefault();
  };

  const [levelsOpen, setLevelsOpen] = useState(false);
  const toggleLevels = (event) => {
    setLevelsOpen(!levelsOpen);
    event.preventDefault();
  };

  const [widgetsOpen, setWidgetsOpen] = useState(false);
  const toggleWidgets = (event) => {
    setWidgetsOpen(!widgetsOpen);
    event.preventDefault();
  };

  const [chartsOpen, setChartsOpen] = useState(false);
  const toggleCharts = (event) => {
    setChartsOpen(!chartsOpen);
    event.preventDefault();
  };

  const [uiKitComponentsOpen, setUiKitComponents] = useState(false);
  const toggleUiKitComponents = (event) => {
    setUiKitComponents(!uiKitComponentsOpen);
    event.preventDefault();
  };

  const [formsComponentsOpen, setFormsComponents] = useState(false);
  const toggleFormsComponents = (event) => {
    setFormsComponents(!formsComponentsOpen);
    event.preventDefault();
  };

  const [collapsedLayoutOpen, setCollapsedLayoutOpen] = useState(false);
  const toggleCollapsedLayout = (event) => {
    setCollapsedLayoutOpen(!collapsedLayoutOpen);
    event.preventDefault();
  };

  const [pagesLoginOpen, setPagesLoginOpen] = useState(false);
  const togglePagesLogin = (event) => {
    setPagesLoginOpen(!pagesLoginOpen);
    event.preventDefault();
  };

  const [pagesRegisterOpen, setPagesRegisterOpen] = useState(false);
  const togglePagesRegister = (event) => {
    setPagesRegisterOpen(!pagesRegisterOpen);
    event.preventDefault();
  };

  const [pagesRecoverOpen, setPagesRecoverOpen] = useState(false);
  const togglePagesRecover = (event) => {
    setPagesRecoverOpen(!pagesRecoverOpen);
    event.preventDefault();
  };

  const handleLogOut = () => {
    lscache.flush();
  };
  useEffect(() => {
    getData(0);
  }, []);

  const userID = lscache.get("userID");

  const getData = async (pageToLoad) => {
    // setIsPageLoading(true);
    // console.log("LOADING PAGE: ", pageToLoad + 1);
    // await REST.get(`/getAllTicketsForListing?page=${pageToLoad}&size=10`)
    //   .then((answer) => {
    //     setData(answer.data.content);
    //
    //     // apel backend pentru returnare lista apeluri
    //   })
    //   .catch((err) => {
    //     if (err && err.response && err.response.data) {
    //       const { message } = err.response.data;
    //     }
    //   });
    setIsPageLoading(false);
  };

  if (isPageLoading) {
    return <div></div>;
  } else {
    return (
      <>
        <PerfectScrollbar>
          {/* {sidebarUserbox && <SidebarUserbox />} */}
          <div className='sidebar-navigation'>
            <div className='sidebar-header'>
              <span>Navigation menu</span>
            </div>
            <ul>
              {userID === 14 || userID === 17 ? (
                <>
                  <li>
                    <NavLink
                      activeClassName='active'
                      // onClick={handleLogOut}
                      className='nav-link-simple'
                      to='/payments'
                    >
                      <span className='sidebar-icon'>
                        <AccountBalanceWallet />
                      </span>
                      <span className='sidebar-item-label'>Payments</span>
                      <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                        <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                      </span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName='active'
                      // onClick={handleLogOut}
                      className='nav-link-simple'
                      to='/my-transactions'
                    >
                      <span className='sidebar-icon'>
                        <Receipt />
                      </span>
                      <span className='sidebar-item-label'>
                        My Own Transactions
                      </span>
                      <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                        <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                      </span>
                    </NavLink>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <NavLink
                      activeClassName='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard'
                    >
                      <span className='sidebar-icon'>
                        <SecurityTwoToneIcon />
                      </span>
                      <span className='sidebar-item-label'>
                        <div>Dashboard</div>
                      </span>
                      <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                        <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      activeClassName='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/usersList'
                    >
                      <span className='sidebar-icon'>
                        <AccountCircleTwoToneIcon />
                      </span>
                      <span className='sidebar-item-label'>
                        <div>Users list</div>
                      </span>
                      <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                        <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      activeClassName='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/accountsList'
                    >
                      <span className='sidebar-icon'>
                        <BusinessCenterTwoToneIcon />
                      </span>
                      <span className='sidebar-item-label'>
                        <div>Accounts list</div>
                      </span>
                      <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                        <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                      </span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      activeClassName='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/transactionsList'
                    >
                      <span className='sidebar-icon'>
                        <BallotTwoToneIcon />
                      </span>
                      <span className='sidebar-item-label'>
                        <div>Transactions list</div>
                        {/* <div className="badge badge-danger rounded-circle mr-4">
                      {console.log("[sidebar] elemetns", data.length)}
                      {data.length ? <div>{data.length}</div> : <div>0</div>}
                    </div> */}
                      </span>
                      <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                        <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                      </span>
                    </NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink
                  activeClassName='active'
                  onClick={handleLogOut}
                  className='nav-link-simple'
                  to='/login'
                >
                  <span className='sidebar-icon'>
                    <ExitToAppIcon />
                  </span>
                  <span className='sidebar-item-label'>Logout</span>
                  <span className='sidebar-icon-indicator sidebar-icon-indicator-right'>
                    <ChevronRightTwoToneIcon style={{ color: "#ffcc67" }} />
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </PerfectScrollbar>
      </>
    );
  }
};
const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,

  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
