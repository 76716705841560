import React from "react";

import {
  Table,
  InputAdornment,
  Card,
  TextField
} from "@material-ui/core";

import moment from 'moment';
import Pagination from "@material-ui/lab/Pagination";
import SearchTwoToneIcon from "@material-ui/icons/SearchTwoTone";

const formatterEUR = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
});

const formatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const formatterGBP = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
});

const formatterRON = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'RON',
});

const Tables = props => {
  const {
    data,
    maxPage,
    handleChangePage,
  } = props;

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="d-flex justify-content-between px-4 py-3">
          {/* <div className="search-wrapper">
            <TextField
              variant="outlined"
              size="small"
              id="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div> */}
        </div>
        <div className="table-responsive-md">
          <Table className="table table-hover text-nowrap mb-0">
            <thead>
              <tr>
                <th className="bg-white text-left">ID</th>
                <th className="bg-white">USER</th>
                <th className="bg-white ">IBAN</th>
                <th className="bg-white">AMOUNT</th>
                <th className="bg-white ">LAST USE</th>
                <th className="bg-white">PRIVATE BANKING</th>
                <th className="bg-white">VALID</th>
              </tr>
            </thead>
            <tbody>
              {data.map(item => (
                <tr>
                  <td className="font-weight-bold">#{item.id}</td>
                  <td>{item.user.firstName} {item.user.lastName}</td>
                  <td>
                    <div
                      className="avatar-icon-wrapper avatar-icon-sm"
                      title="Lili Pemberton"
                    >
                      {item.iban}
                    </div>
                  </td>
                  <td >
                    <div className="d-flex align-items-center">
                      {item.currency.value == "EUR" ?
                        <div>
                          {formatterEUR.format(item.amount)}
                        </div> : null}
                      {item.currency.value == "USD" ?
                        <div>
                          {formatterUSD.format(item.amount)}
                        </div> : null}
                      {item.currency.value == "GBP" ?
                        <div>
                          {formatterGBP.format(item.amount)}
                        </div> : null}
                      {item.currency.value == "RON" ?
                        <div>
                          {formatterRON.format(item.amount)}
                        </div> : null}
                    </div>
                  </td>
                  <td >
                    <div className="d-flex align-items-center">
                      {moment(item.lastUpdate).format("DD.MM.YYYY HH:MM")}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {item.privateBanking ? <div>YES</div> : <div>NO</div>}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {item.valid ? <div>YES</div> : <div>NO</div>}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={maxPage}
            onChange={handleChangePage}
          />
        </div>
      </Card>
    </>
  );
};

export default Tables;
