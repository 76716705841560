import React, { useState } from "react";
import lscache from "lscache";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Badge, Menu, Button, Tooltip, Divider } from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import avatar200 from "../../assets/images/avatars/avatar200.png";
import { NavLink } from "react-router-dom";

const SidebarUserbox = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="app-sidebar--userbox">
        <Box className="card-tr-actions">
          <Button
            variant="text"
            onClick={handleClick}
            className="ml-2 p-0 d-30 border-0 btn-transition-none text-white-50"
            disableRipple
          >
            <FontAwesomeIcon
              icon={["fas", "ellipsis-h"]}
              className="font-size-lg"
            />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            open={Boolean(anchorEl)}
            classes={{ list: "p-0" }}
            onClose={handleClose}
          >
            <div className="dropdown-menu-lg overflow-hidden p-0">
              <div className="align-box-row align-items-center p-3">
                <div className="avatar-icon-wrapper avatar-icon-md">
                  <div className="avatar-icon rounded-circle">
                    <img alt="..." src={avatar200} />
                  </div>
                </div>
                <div className="pl-2">
                  <span className="font-weight-bold d-block">
                  {lscache.get("operatorName")}
                  </span>
                  <div className="badge badge-success border-0">Activ</div>
                </div>
              </div>
              <Divider className="w-100" />
              <div className="d-flex py-3 justify-content-center">
                <div className="d-flex align-items-center">
                  <div>
                    <FontAwesomeIcon
                      icon={["far", "user"]}
                      className="font-size-xxl text-success"
                    />
                  </div>
                  <div className="pl-3 line-height-sm">
                    <b className="font-size-lg">14,596</b>
                    <span className="text-black-50 d-block">
                      apeluri preluate
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        </Box>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            variant="dot"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent=" "
            overlap="circle"
            classes={{ badge: "bg-success badge-circle" }}
          >
            <div className="avatar-icon rounded-circle">
              <img alt="..." src={avatar200} />
            </div>
          </Badge>
        </div>
        <div className="my-3 userbox-details">
          <span>{lscache.get("operatorName")}</span>
          <small className="d-block text-white-50">
            ({lscache.get("operatorType")})
          </small>
        </div>
      </div>
    </>
  );
};

export default SidebarUserbox;
