import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { ClimbingBoxLoader } from "react-spinners";

import { RouteWithLayout } from "./components";
import {
  Website as WebsiteLayout,
  IsLogged as IsLoggedLayout,
} from "./layouts";
import { ThemeProvider } from "@material-ui/styles";

import {
  Homepage,
  Login,
  NotFound,
  UsersList,
  TransactionsList,
  Dashboard,
  AccountsList,
} from "./views";
import Payments from "views/Website/Payments";
import PersonalTransactions from "views/Website/PersonalTransactions";

const Routes = () => {
  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key='loading'
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
                <div className='d-flex align-items-center flex-column px-4'>
                  <ClimbingBoxLoader color={"#3c44b1"} loading={true} />
                </div>
                <div className='text-muted font-size-xl text-center pt-3'>
                  Please wait while we load the live preview examples
                  <span className='font-size-lg d-block text-dark'>
                    This live preview instance can be slower than a real
                    production build!
                  </span>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  return (
    <Switch>
      <RouteWithLayout
        component={Homepage}
        exact
        layout={WebsiteLayout}
        path='/'
      />

      <RouteWithLayout
        component={Login}
        exact
        layout={WebsiteLayout}
        path='/login'
      />

      <RouteWithLayout
        component={Dashboard}
        exact
        layout={IsLoggedLayout}
        path='/dashboard'
      />

      <RouteWithLayout
        component={UsersList}
        exact
        layout={IsLoggedLayout}
        path='/usersList'
      />

      <RouteWithLayout
        component={AccountsList}
        exact
        layout={IsLoggedLayout}
        path='/accountsList'
      />

      <RouteWithLayout
        component={TransactionsList}
        exact
        layout={IsLoggedLayout}
        path='/transactionsList'
      />

      <RouteWithLayout
        component={NotFound}
        exact
        layout={WebsiteLayout}
        path='/404'
      />

      <RouteWithLayout
        component={Payments}
        exact
        layout={IsLoggedLayout}
        path='/payments'
      />
      <RouteWithLayout
        component={PersonalTransactions}
        exact
        layout={IsLoggedLayout}
        path='/my-transactions'
      />
      <Redirect to='/404' />
    </Switch>
  );
};

export default Routes;
